import React, { useEffect, useMemo, useState } from "react";
import "./App.css";
//import dashjs from "dashjs";
//import ReactPlayer from "react-player/lazy";
import Button from "react-bootstrap/Button";
import Preview from "./Preview";
//import DashPlayer from './DashPlayer';

function App() {
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [poster, setPoster] = useState("");
  const [descript, setDescript] = useState("");
  const [ancho, setAncho] = useState();
  const [selected, setSelected] = useState();

  const handleResize = () => {
    setAncho(window.innerWidth - 18);
  };

  const [countVideo, setcountVideo] = useState(0);

  const playPreview = (index) => {
    document.getElementById("prev" + index).click();
  };

  const nextPreview = (data, index) => {
    setPoster(data.poster);
    setTitle(data.title);
    setUrl(data.url);
    setDescript(data.description);
    setSelected(index);
    setcountVideo(index);
    playPreview(index);
    console.log("Rproduciendo:", index);
  };

  const movies = useMemo(() => {
    // Initialize the movies array here
    return [
      {
        title: "Robot Salvaje",
        poster: "https://images3.alphacoders.com/136/1367325.jpeg",
        url: "https://brokers.veemind.uk/drm/10.8.0.3/5112BE3D-216C-4C33-B875-CB8085736B92/preview.m3u8",
        description:
          "Esta aventura épica cuenta la historia de una robot, Rozzum 7-1-3-4, 'Roz' para abreviar, que naufraga en una isla inhabitada y debe aprender a adaptarse a los entornos rigurosos construyendo relaciones con los animales de la isla y convirtiéndose en la madre adoptiva de una cría de ganso huérfana.",
      },
      {
        title: "Deadpool & Wolverin",
        poster:
          "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/5298bac0-b8bf-4c80-af67-725c1272dbb0/dhu2kqq-2211762b-8133-44dc-b189-18f14407e0d7.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzUyOThiYWMwLWI4YmYtNGM4MC1hZjY3LTcyNWMxMjcyZGJiMFwvZGh1MmtxcS0yMjExNzYyYi04MTMzLTQ0ZGMtYjE4OS0xOGYxNDQwN2UwZDcuanBnIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.lNjOz3rDiXUR-0W69lYsvN1G80Od5RhWRxICAtgtUXg",
        url: "https://brokers.veemind.uk/drm/10.8.0.3/ED9796FF-7284-4996-BB1B-E052AA63AFF7/preview.m3u8",
        description:
          "Un apático Wade Wilson se esfuerza por adaptarse a la vida civil. Sus días como el mercenario moralmente flexible, Deadpool, han quedado atrás. Cuando su mundo se enfrenta a una amenaza existencial, reaciamente Wade debe ponerse el traje de nuevo, junto con un aún más reacio… ¿muy reacio?",
      },
      {
        title: "Minions",
        poster:
          "https://i0.wp.com/imgs.hipertextual.com/wp-content/uploads/2015/07/Minions-movie-still01.jpg",
        url: "https://brokers.veemind.uk/drm/10.8.0.3/D5FE5320-237F-4CEF-BDC2-90CDB01816FD/preview.m3u8",
        description:
          "Los minions, ingenuos y torpes ayudantes de villano, llevan buscando, desde el principio de los tiempos, un verdadero malhechor al que servir. A lo largo de una evolución de millones de años, los minions se han puesto al servicio de los amos más despreciables.",
      },
      {
        title: "Aliens vs Depredador 2",
        poster:
          "https://hips.hearstapps.com/hmg-prod/images/alien-vs-predator-2-1659957285.jpg",
        url: "https://brokers.veemind.uk/drm/10.8.0.3/C561E0B3-4514-4E73-815B-5F24DB059AE1/preview.m3u8",
        description:
          "Una nave exploradora de los predators se estrella en un pueblo de Colorado. Los aliens a bordo escapan y matan a todos los predators, excepto a uno, que desea vengarse. Los humanos se ven en medio de esta guerra entre sanguinarios extraterrestres.",
      },
      {
        title: "Apocalipsis Z",
        poster:
          "https://www.atlantico.net/asset/thumbnail,1920,1080,center,center/media/atlantico/images/2024/07/10/2024071019563710196.jpg",
        url: "https://brokers.veemind.uk/drm/10.8.0.3/F5E4817F-EC62-46DC-AF13-C1CC565C1C95/preview.m3u8",
        description:
          "Un joven abogado que vive en una pequeña ciudad, observa estupefacto el goteo de noticias hasta que esa misteriosa plaga llega hasta su puerta.",
      },
      {
        title: "Canario Negro",
        poster:
          "https://i0.wp.com/www.micropsiacine.com/wp-content/uploads/2024/10/canary-black-trailer.jpg",
        url: "https://brokers.veemind.uk/drm/10.8.0.3/2C2DB7ED-3319-4F94-8A98-33246A268523/preview.m3u8",
        description:
          "Avery Graves es una agente de la CIA que es chantajeada por terroristas para salvar a su esposo secuestrado. Separada de su equipo, recurre a sus contactos en el bajo mundo para que la ayuden a encontrar la información que quieren los secuestradores.",
      },
    ];
  }, []);

  const movieData = movies.length - 1;

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    setAncho(window.innerWidth - 18);
    //setTimeout(() => {
    playPreview(countVideo);
    //}, 3000);
  }, [movies, countVideo]);

  return (
    <div
      style={{
        display: 'flex',
        marginTop: 0,
        paddingTop: 0,
        backgroundColor: "rgba(60,0,0)",
        flexDirection: 'column',
        borderRadius: 5
      }}
    >
      
      <div
        style={{
          width: ancho,
          backgroundColor: "rgba(70,0,0,1)",
          position: "relative",
          margin: 0,
          padding: 0,
        }}
      >
        <Preview
          url={url}
          title={title}
          poster={poster}
          inicial={Math.round(Math.random() * 3000)}
          description={descript}
          width={ancho}
          height={ancho < 1000 ? 480 : ancho / 3}
          actions={{
            countVideo,
            setcountVideo,
            playPreview,
            movieData,
            setSelected,
          }}
        />
        <div
          style={{
            zIndex: 10000,
            position: "relative",
            overflow: "hidden",
            height: 160,
            backgroundColor: 'rgb(30,0,0)',
            boxShadow: '0px 0px 100px 1px rgba(0,0,0)',
            width: ancho,
          }}
        >
          {/* Contenedor de las películas */}
          <div
            style={{
              display: 'flex',
              marginTop: 5,
              position: 'relative',
              width: (320 * movies.length), // Ancho total depende de la cantidad de películas
              height: 150,
              transform: `translateX(-${(selected > 0 ? (selected * 320) : 0)}px)`,
              transition: "transform 0.5s ease",
            }}
          >
            {movies.map((data, index) => (
              <div
              key={index}
              style={{
                width: 320,
                padding: 5,
                display: 'flex',
                position: 'relative'
              }}>
                <Button
                  id={"prev" + index}
                  onClick={() => {
                    nextPreview(data, index);
                  }}
                  style={{
                    display: 'flex',
                    backgroundImage: `url(${data.poster})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    width: 320,
                    height: 140,
                    border:
                      selected === index ? "4px solid #fff" : "0px solid #000",
                    justifyContent: 'start',
                    alignItems: 'start'
                  }}
                  variant="transparent"
                >
                  <div style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    fontSize: 18,
                    fontWeight: 'bold',
                    textShadow: '1px 1px 0px white, -1px -1px 0px white, 1px -1px 0px white, -1px 1px 0px white'
                  }}>{data.title}</div>
                </Button>
                </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
