import React, { useEffect, useRef, useState } from "react";
import Hls from "@tianfeng98/hls.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Button, ProgressBar } from "react-bootstrap";
//import { Button } from "react-bootstrap";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faMaximize } from "@fortawesome/free-solid-svg-icons";

const Preview = (props) => {
  const { title, poster, inicial, description } = props;
  //const [ofit, setOfit] = useState("fill");
  const videoRef = useRef(null);
  const reproductor = useRef(null);
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NjBmMDAzNGI5MzhhZmI0ODFhYTFmNmYiLCJlbWFpbCI6ImNlb0BpbWVzaC5hcHAiLCJpYXQiOjE3MzAyMjYzNzAsImV4cCI6MTczMjgxODM3MH0.FniwksdvEe4sNNjKTdNaBg1c3AJW-fRe-JNcwOUO_cM";
  const [videoURL, setVideoURL] = useState(props.url);
  const [loading, setLoading] = useState(true);
  const maxTime = 40;

  useEffect(() => {
    const hlsConfig = {
      autoStartLoad: true,
      debug: false,
      recover: -1,
      initialLiveManifestSize: 1,
      maxBufferSize: 10 * 1024 * 1024,
      maxBufferLength: 30,
      maxMaxBufferLength: 60,
      enableWorker: true,
      lowLatencyMode: true,
      backBufferLength: 90,
      maxBufferHole: 0.5,
      highBufferWatchdogPeriod: 2,
      nudgeOffset: 0.1,
      nudgeMaxRetry: 3,
      maxFragLookUpTolerance: 0.25,
      liveSyncDurationCount: 3,
      overrideNative: true,
      liveMaxLatencyDurationCount: Infinity,
      liveDurationInfinity: true,
      preferManagedMediaSource: true,
      fragLoadPolicy: {
        default: {
          maxTimeToFirstByteMs: 9000,
          maxLoadTimeMs: 100000,
          timeoutRetry: {
            maxNumRetry: 2,
            retryDelayMs: 0,
            maxRetryDelayMs: 0,
          },
          errorRetry: {
            maxNumRetry: 5,
            retryDelayMs: 3000,
            maxRetryDelayMs: 15000,
            backoff: "linear",
          },
        },
      },
      startFragPrefetch: false,
      testBandwidth: true,
      progressive: false,
      nextLoadLevel: 0,
      fpsDroppedMonitoringPeriod: 5000,
      fpsDroppedMonitoringThreshold: 0.2,
      appendErrorMaxRetry: 3,
      enableWebVTT: false,
      capLevelToPlayerSize: true,
      startLevel: -1,
      xhrSetup: (xhr) => {
        xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      },
    };

    if (Hls.isSupported()) {
      setVideoURL(props.url);
      const hls = new Hls(hlsConfig);

      hls.loadSource(videoURL);
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        try {
          //videoRef.current.currentTime = props.inicial;
          videoRef.current.play();
        } catch (e) {
          console.log(e);
        }
      });
    }
  }, [props, videoURL, token]);

  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const handleTimeUpdate = () => {
      try {
        if (videoRef.current) {
          setCurrentTime(videoRef.current.currentTime);

          if (videoRef.current.currentTime >= videoRef.current.duration) {
            videoRef.current.pause();
            videoRef.current.src = "";
            videoRef.current.load();
            if (props.actions.countVideo < props.actions.movieData) {
              props.actions.setcountVideo(props.actions.countVideo + 1);
              props.actions.playPreview(props.actions.countVideo + 1);
            } else {
              props.actions.setSelected(0);
              props.actions.playPreview(0);
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    const videoElement = videoRef.current;
    videoElement.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      videoElement.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [props, currentTime, inicial, maxTime]);

  const handleVideoEnded = () => {
    console.log("Video Finalizado");
    //props.actions.setcountVideo(props.actions.countVideo + 1);
    //props.actions.playPreview(props.actions.countVideo + 1);
  };

  return (
    <div
      style={{
        // Reproductor
        position: "relative",
        backgroundColor: "#700",
        clear: "both",
        padding: 0,
        width: props.width,
        height: props.height,
      }}
      ref={reproductor}
    >
      <div
        style={{
          // Controles
          position: "absolute",
          zIndex: 1000,
          left: 0,
          right: 0,
          width: "100%",
          height: "100%",
          flexDirection: "row",
          backgroundImage:
            "linear-gradient(to right, rgba(60,0,0,0.9), rgba(60,0,0,0.4), rgba(0,0,0,0), rgba(60,0,0,0.4), rgba(60,0,0,0.9))",
        }}
      >
        <div
        style={{
          width: "100%",
          height: 60,
          backgroundColor: 'rgba(0,0,0,0.1)',
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            paddingLeft: 30,
            fontSize: 40,
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          NetIX
        </div>
      </div>
        <div
          style={{
            // Barra Superior
            paddingTop: 10,
            paddingLeft: 30,
            paddingRight: 30,
            width: props.width,
            justifyContent: "end",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Row>
            <Col
              style={{
                color: "white",
                fontSize: 32,
                fontWeight: "bold",
                textShadow: "0px 0px 5px #000",
              }}
            >
              {title}
            </Col>
          </Row>

          <Row>
            <Col
              sm={12}
              style={{
                fontSize: 16,
                color: "white",
                textShadow: "0px 0px 5px #000",
                textAlign: "justify",
              }}
            >
              {description}
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              {props.url && (
                <Button variant="light" className="rounded-circle p-2 shadow">
                  <FontAwesomeIcon
                    icon={faCirclePlay}
                    color="red"
                    className="fa-4x"
                  />
                </Button>
              )}
            </Col>
          </Row>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 100,
            }}
          >
            {props.url && loading && (
              <div
                style={{
                  display: "flex",
                }}
              >
                <FontAwesomeIcon
                  icon={faSpinner}
                  pulse
                  className="fa-fw fa-5x"
                  color="white"
                />
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            // Barra Inferior
            position: "absolute",
            bottom: 10,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: 30,
            paddingRight: 30,
          }}
        >
          <div style={{ width: "100%", height: 20 }}>
            {videoRef.current?.currentTime ? (
              <ProgressBar
                data-bs-theme="dark"
                variant="danger"
                style={{ height: 4 }}
                now={videoRef.current.currentTime}
                min={0}
                max={videoRef.current.duration}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 100,
          backgroundImage: `url(${poster})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: videoRef.current?.currentTime ? "none" : "block",
        }}
      ></div>
      <video
        ref={videoRef}
        style={{
          clear: "both",
          width: "100%",
          height: "100%",
          objectFit: "fill",
          backgroundColor: "#700",

          //boxShadow: "0px 0px 10px #666",
        }}
        onLoadStart={() => {
          videoRef.current.src === "" && setLoading(true);
        }}
        onPlaying={() => setLoading(false)}
        onEnded={handleVideoEnded}
        preload="metadata"
        playsInline
        muted
        autoPlay
        controls={false}
      />
    </div>
  );
};

export default Preview;
